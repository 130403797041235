<template>
    <v-app>
        <div class="row">
            <div class="col">
                <v-card>
                    <v-card-title>
                        <span>Envío #{{envio_id}}</span>
                    </v-card-title>
                    <v-card-text>
                        <div class="row">
                            <div class="col">
                                <div class="kt-portlet">
                                    <div class="kt-portlet__body  kt-portlet__body--fit">
                                        <div class="row row-no-padding row-col-separator-xl">
                                            <div class="col-md-12 col-lg-6 col-xl-3">
                                                <div class="kt-widget24">
                                                    <div class="kt-widget24__details">
                                                        <div class="kt-widget24__info">
                                                            <h4 class="kt-widget24__title">
                                                                ID Envío
                                                            </h4>
                                                            <span class="kt-widget24__desc">
                                                                Interno
                                                            </span>
                                                        </div>
                                                        <span class="kt-widget24__stats kt-font-brand">
                                                            {{envio_id}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-lg-6 col-xl-3">
                                                <div class="kt-widget24">
                                                    <div class="kt-widget24__details">
                                                        <div class="kt-widget24__info">
                                                            <h4 class="kt-widget24__title">
                                                                Referencia
                                                            </h4>
                                                            <span class="kt-widget24__desc">
                                                                Fuente
                                                            </span>
                                                        </div>
                                                        <span class="kt-widget24__stats kt-font-warning">
                                                            {{envio.order_reference}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-lg-6 col-xl-3">
                                                <div class="kt-widget24">
                                                    <div class="kt-widget24__details">
                                                        <div class="kt-widget24__info">
                                                            <h4 class="kt-widget24__title">
                                                                Fuente
                                                            </h4>
                                                            <span class="kt-widget24__desc">
                                                                Tienda / Marketplace
                                                            </span>
                                                        </div>
                                                        <span class="kt-widget24__stats kt-font-danger">
                                                            {{source.name}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-lg-6 col-xl-3">
                                                <div class="kt-widget24">
                                                    <div class="kt-widget24__details">
                                                        <div class="kt-widget24__info">
                                                            <h4 class="kt-widget24__title">
                                                                Fecha
                                                            </h4>
                                                            <span class="kt-widget24__desc">
                                                                Ingresado
                                                            </span>
                                                        </div>
                                                        <span class="kt-widget24__stats kt-font-success">
                                                            {{envio.created_at}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <v-card
                            width="100%"
                            >
                                <v-card-text class="py-0">
                                    <h3 class="headline">Número de seguimiento: {{envio.shipping_number}}</h3>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div class="row">
                            <v-card
                            width="100%"
                            >
                                <v-card-text class="py-0">
                                    <v-timeline
                                    align-top
                                    dense
                                    >
                                        <v-timeline-item
                                            color="pink"
                                            small
                                            v-if="shipmentHistory.find(e => e.shipment_state.type == 'DEFAULT_IN_SHIPPING')"
                                        >
                                            <v-row class="pt-1">
                                            <v-col cols="3">
                                                <strong>{{$moment(shipmentHistory.find(e => e.shipment_state.type == 'DEFAULT_IN_SHIPPING').created_at).format('MMMM Do YYYY, h:mm:ss a')}}</strong>
                                            </v-col>
                                            <v-col>
                                                <strong>{{shipmentHistory.find(e => e.shipment_state.type == 'DEFAULT_IN_SHIPPING').shipment_state.name}}</strong>
                                                <div class="caption">Courier: {{carrier.name}}</div>
                                            </v-col>
                                            </v-row>
                                        </v-timeline-item>
                                        
                                        <v-timeline-item
                                            color="teal lighten-3"
                                            small
                                            v-if="shipmentHistory.find(e => e.shipment_state.type == 'DEFAULT_LABEL_OUT')"
                                        >
                                            <v-row class="pt-1">
                                            <v-col cols="3">
                                                <strong>{{$moment(shipmentHistory.find(e => e.shipment_state.type == 'DEFAULT_LABEL_OUT').created_at).format('MMMM Do YYYY, h:mm:ss a')}}</strong>
                                            </v-col>
                                            <v-col>
                                                <strong>{{shipmentHistory.find(e => e.shipment_state.type == 'DEFAULT_LABEL_OUT').shipment_state.name}}</strong>
                                                <div class="caption mb-2">Courier: {{carrier.name}}</div>
                                            </v-col>
                                            </v-row>
                                        </v-timeline-item>
                                        <!--
                                        <v-timeline-item
                                            color="pink"
                                            small
                                        >
                                            <v-row class="pt-1">
                                            <v-col cols="3">
                                                <strong>12pm</strong>
                                            </v-col>
                                            <v-col>
                                                <strong>Lunch break</strong>
                                            </v-col>
                                            </v-row>
                                        </v-timeline-item>

                                        <v-timeline-item
                                            color="teal lighten-3"
                                            small
                                        >
                                            <v-row class="pt-1">
                                            <v-col cols="3">
                                                <strong>9-11am</strong>
                                            </v-col>
                                            <v-col>
                                                <strong>Finish Home Screen</strong>
                                                <div class="caption">Web App</div>
                                            </v-col>
                                            </v-row>
                                        </v-timeline-item>
                                        -->
                                    </v-timeline>
                                </v-card-text>
                            </v-card>
                        </div>
                        <!--
                        <div class="row">
                            <div class="col">
                                <b-tabs content-class="mt-3" fill>
                                    <b-tab title="Productos" active>
                                        <v-simple-table fixed-header height="300px">
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th class="text-left">ID</th>
                                                    <th class="text-left">Nombre</th>
                                                    <th class="text-left">SKU</th>
                                                    <th class="text-left">EAN</th>
                                                    <th class="text-left">Cantidad</th>
                                                    <th class="text-left">Total</th>
                                                    <th class="text-left">Fecha ingreso al sistema</th>
                                                    <th class="text-left">Store</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(item,index) in orderDetail" :key="index">
                                                    <td>{{ item.id }}</td>
                                                    <td>{{ item.product }}</td>
                                                    <td>{{ item.sku }}</td>
                                                    <td>{{ item.ean }}</td>
                                                    <td>{{ item.quantity }}</td>
                                                    <td>{{ item.total }}</td>
                                                    <td>{{ $moment(item.created_at).format('DD-MM-YYYY HH:mm') }}</td>
                                                    <td>{{ item.store.name }}</td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </b-tab>
                                    <b-tab title="Cliente">
                                        <v-list two-line subheader>
                                            <v-subheader style="font-size: 1.2rem;"><v-badge style="cursor: pointer;" icon="mdi mdi-pencil" @click.native="editarCliente" > Datos Cliente</v-badge></v-subheader>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Nombre</v-list-item-title>
                                                    <v-list-item-subtitle>{{customer.fullname}}</v-list-item-subtitle>
                                                    <v-list-item-title>Email</v-list-item-title>
                                                    <v-list-item-subtitle>{{customer.email}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-subheader  style="font-size: 1.2rem;">Metadatos</v-subheader>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Fecha creación</v-list-item-title>
                                                    <v-list-item-subtitle>{{customer.created_at}}</v-list-item-subtitle>
                                                    <v-list-item-title>Store</v-list-item-title>
                                                    <v-list-item-subtitle>{{customer.store.name}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </b-tab>
                                    <b-tab title="Dirección">
                                        <v-list two-line subheader>
                                            <v-subheader style="font-size: 1.2rem;"><v-badge style="cursor: pointer;" icon="mdi mdi-pencil" @click.native="editarDireccion" > Datos Dirección</v-badge></v-subheader>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>ID</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.id}}</v-list-item-subtitle>
                                                    <v-list-item-title>Calle</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.street}}</v-list-item-subtitle>
                                                    <v-list-item-title>Número</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.number}}</v-list-item-subtitle>
                                                    <v-list-item-title>Dpto</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.dpto}}</v-list-item-subtitle>
                                                    <v-list-item-title>Ciudad</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.city}}</v-list-item-subtitle>
                                                    <v-list-item-title>Provincia</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.province}}</v-list-item-subtitle>
                                                    <v-list-item-title>Región</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.state}}</v-list-item-subtitle>
                                                    <v-list-item-title>DNI</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.dni}}</v-list-item-subtitle>
                                                    <v-list-item-title>Teléfono</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.phone}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-subheader  style="font-size: 1.2rem;">Datos Relacionados</v-subheader>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Cliente</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.customer.fullname}} (ID: {{address.customer.id}})</v-list-item-subtitle>
                                                    <v-list-item-title>Store</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.store.name}} (ID: {{address.store.id}})</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </b-tab>
                                    <b-tab title="Acciones">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <v-card>
                                                    <v-card-text  class="grey lighten-5">
                                                        <v-select
                                                            v-model="current_state"
                                                            :items="orderStatesList"
                                                            :rules="[v => !!v || 'Debes elegir un estado']"
                                                            label="Cambiar Estado del pedido"
                                                            required
                                                        ></v-select>
                                                    </v-card-text>
                                                    <v-card-text style=" position: relative">
                                                        <v-btn
                                                            absolute
                                                            dark
                                                            fab
                                                            top
                                                            right
                                                            color="pink"
                                                            @click="sendFormOrderStates"
                                                        >
                                                            <v-icon>mdi-content-save</v-icon>
                                                        </v-btn>
                                                    </v-card-text>
                                                </v-card>
                                                        
                                            </div>
                                            
                                        </div>
                                    </b-tab>
                                </b-tabs>
                                        
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <v-card
                                    color="#385F73"
                                    dark
                                    min-width="250px"
                                >
                                    <v-card-title class="headline">Shipping number</v-card-title>
                                    <v-card-subtitle>{{order.shipping_number}}</v-card-subtitle>
                                </v-card>
                            </div>
                            <div class="col">
                                <v-card
                                    color="#385F73"
                                    dark
                                    min-width="250px"
                                >
                                    <v-card-title class="headline">Total productos</v-card-title>
                                    <v-card-subtitle>{{order.total_products}}</v-card-subtitle>
                                </v-card>
                            </div>
                            <div class="col">
                                <v-card
                                    color="#385F73"
                                    dark
                                    min-width="250px"
                                >
                                    <v-card-title class="headline">Total Shipping</v-card-title>
                                    <v-card-subtitle>{{order.total_shipping}}</v-card-subtitle>
                                </v-card>
                            </div>
                            <div class="col">
                                <v-card
                                    color="#385F73"
                                    dark
                                    min-width="250px"
                                >
                                    <v-card-title class="headline">Total descuentos</v-card-title>
                                    <v-card-subtitle>{{order.total_discount}}</v-card-subtitle>
                                </v-card>
                            </div>
                            <div class="col">
                                <v-card
                                    color="#385F73"
                                    dark
                                    min-width="250px"
                                >
                                    <v-card-title class="headline">Total pagado</v-card-title>
                                    <v-card-subtitle>{{order.total_paid}}</v-card-subtitle>
                                </v-card>
                            </div>
                            <div class="col">
                                <v-card
                                    color="#385F73"
                                    dark
                                    min-width="250px"
                                >
                                    <v-card-title class="headline">Fecha desde Marketplace</v-card-title>
                                    <v-card-subtitle>{{order.source_date}}</v-card-subtitle>
                                </v-card>
                            </div>
                            <div class="col">
                                <v-card
                                    color="#385F73"
                                    dark
                                    min-width="250px"
                                >
                                    <v-card-title class="headline">Estado Orden</v-card-title>
                                    <v-card-subtitle>{{order.order_state_name}}</v-card-subtitle>
                                </v-card>
                            </div>
                        </div>
                        -->
                    </v-card-text>
                </v-card>
            </div>
        </div>
        <!--
        <v-dialog v-model="showDialogEditarDatosCliente" max-width="1000px">
            <v-card>
                <v-card-title>
                    <span>Editar Cliente #{{customer.id}}</span>
                </v-card-title>
                <v-card-text>
                    <v-form
                    ref="form"
                    lazy-validation
                    >
                        <v-text-field
                            v-model="customer.firstname"
                            :counter="200"
                            label="Nombre"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="customer.lastname"
                            :counter="200"
                            label="Apellidos"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="customer.email"
                            :rules="emailRules"
                            label="E-mail"
                            required
                        ></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        text
                        @click="showDialogEditarDatosCliente = false"
                        >
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="success"
                        text
                        @click="sendFormDatosCliente"
                        >
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogEditarDatosDireccion" max-width="1000px">
            <v-card>
                <v-card-title>
                    <span>Editar Dirección #{{address.id}}</span>
                </v-card-title>
                <v-card-text>
                    <v-form
                    ref="form"
                    
                    lazy-validation
                    >
                        <v-text-field
                            v-model="address.street"
                            :rules="defaultRequiredRules"
                            :counter="200"
                            label="Calle"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.number"
                            :rules="defaultRequiredRules"
                            :counter="200"
                            label="Número"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.dpto"
                            :rules="defaultRequiredRules"
                            label="Dpto"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.city"
                            :rules="defaultRequiredRules"
                            label="Ciudad"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.province"
                            :rules="defaultRequiredRules"
                            label="Provincia"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.state"
                            :rules="defaultRequiredRules"
                            label="Región"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.dni"
                            :rules="defaultRequiredRules"
                            label="DNI"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.phone"
                            :rules="defaultRequiredRules"
                            label="Teléfono"
                            required
                        ></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        text
                        @click="showDialogEditarDatosDireccion = false"
                        >
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="success"
                        text
                        @click="sendFormDatosDireccion"
                        >
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        -->
    </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
    data(){
        return{
            showDialogEditarDatosCliente: false,
            showDialogEditarDatosDireccion: false,
            envio_id: undefined,
            envio: {
                address:null,
                address_id:null,
                courier_tracking_id:null,
                created_at:null,
                current_state:null,
                customer_id:null,
                id:null,
                order_reference:null,
                order_state_name:null,
                process_order:null,
                province:null,
                shipping_number:null,
                source:null,
                source_date:null,
                source_id:null,
                state:null,
                store_id:null,
                total_discount:null,
                total_paid:null,
                total_products:null,
                total_shipping:null,
                updated_at: null,
                warehouse_tracking_id: null,
            },
            customer: {
                created_at:null,
                email:null,
                firstname:null,
                fullname:null,
                id:null,
                lastname:null,
                store:{},
                store_id:null,
                updated_at:null,
            },
            address: {
                city:null,
                created_at:null,
                customer:{},
                customer_id:null,
                dni:null,
                dpto:null,
                id:null,
                number:null,
                phone:null,
                province:null,
                state:null,
                store:{},
                store_id:null,
                street:null,
                updated_at:null,
            },
            source: {
                id:null,
                name:null,
                type_id:null
            },
            carrier: {
                id: undefined,
                name: undefined,
            },
            orderHistory: [],
            histories: undefined,
            orderDetail: undefined,
            statusAll: undefined,
            emailRules: [
                value => !!value || 'Requerido.',
                value => (value || '').length <= 200 || 'Máximo 200 caracteres',
                value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Email inválido.'
                },
            ],
            defaultRequiredRules: [
                v => !!v || 'Este campo es obligatorio',
            ],
            shipmentState: undefined,
            orderStatesList: [],
            shipmentHistory: [],
        }
    },
    methods: {
        fetchData(){
            var vm = this;
            this.axios({
                //url: 'http://oldoms.testing.fullkom.com/api/orders/'+this.$route.params.envio
                url: 'shippings/'+this.$route.params.envio
            }).then( response => {
                vm.envio_id = response.data.shipment.id;
                vm.envio = response.data.shipment;
                vm.customer = response.data.customer;
                vm.address = response.data.address;
                vm.source = response.data.source;
                vm.orderDetail = response.data.orderDetail;
                vm.carrier = response.data.carrier;
                vm.shipment_state = response.data.shipment_state;
                vm.shipmentHistory = response.data.shipmentHistory;
                //vm.fetchOrderStates();
            }).catch( error => {
                console.log(error);
                vm.$router.push({name: '404'});
            });
        },
        editarCliente()
        {
            this.showDialogEditarDatosCliente = true;
        },
        editarDireccion()
        {
            this.showDialogEditarDatosDireccion = true;
        },
        sendFormDatosCliente(){
            console.log('actualiazndo datos cliente');
            
            var vm = this;
            this.axios({
                url: 'orders/customer/'+this.customer.id,
                method: 'POST',
                data: {
                    firstname: this.customer.firstname,
                    lastname: this.customer.lastname,
                    email: this.customer.email,
                }
            }).then( (response) => {
                vm.$bvToast.toast(response.data, {
                    title: `Información`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.fetchData();
                vm.showDialogEditarDatosCliente = false;
            }).catch( error => {
                console.log(error);
                vm.showDialogEditarDatosCliente = false;
            });
        },
        sendFormDatosDireccion(){
            console.log('actualiazndo datos direccion');
            var vm = this;
            this.axios({
                url: 'orders/address/'+this.address.id,
                method: 'POST',
                data: this.address
            }).then( (response) => {
                vm.$bvToast.toast(response.data, {
                    title: `Información`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.fetchData();
                vm.showDialogEditarDatosDireccion = false;
            }).catch( error => {
                console.log(error);
                vm.showDialogEditarDatosDireccion = false;
            });
        },
        fetchOrderStates(){
            var vm = this;
            this.axios({
                url: 'orders/states/data_select_input?store='+this.order.store_id
            }).then( response => {
                vm.orderStatesList = response.data;
            }).catch( (error) => {
                console.log(error);
            });
        },
        sendFormOrderStates()
        {
            var vm = this;
            this.axios({
                url: 'orders/'+this.orden_id+'/state',
                method: 'POST',
                data: {
                    state: this.current_state
                }
            }).then( () => {
                vm.$bvToast.toast('Estado actualizado correctamente', {
                    title: `Información`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.fetchData();
            }).catch( (error) => {
                console.log(error);
            });
        }
    },
    created(){
        console.log('parametro :envio',this.$route.params.envio);
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Dashboard Envios", route: "/envios" },
            { title: "Envío "+this.$route.params.envio, route: "/envios/"+this.$route.params.envio },
        ]);
        this.fetchData();
    },
    watch: {
        $route(to, from) {
            this.fetchData();
        }
    }
}
</script>